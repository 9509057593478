.top-bar {
    border-bottom: 1px solid scale-color($secondary-color, $lightness: -25%);
    margin-bottom: $global-margin;

    .site-name {
        color: $white;
        font-weight: bold;
        font-size: $global-font-size*1.3;
        line-height: 1;
        padding: $global-padding;
    }

    .dropdown.menu>li>a {
        padding-top: $global-padding*1.2;
        padding-bottom: $global-padding*1.2;

        &:hover {
            color: $white;
        }
    }

    .is-dropdown-submenu {
        border-color: scale-color($secondary-color, $lightness: -25%);
    }

    .menu {
        a {
            color: $black;

            &:hover {
                color: $white;
            }
        }

        .submenu {
            z-index: 1010;
            a {
                color: $black;

                &:hover {
                    color: $dark-gray;
                }
            }
        }
    }

    .dropdown.menu .is-dropdown-submenu-parent {
        a::after {
            border-color: $black transparent transparent;
        }
    }
}

.unit-title {
    position: relative;
    padding: $global-padding*.25 0 $global-padding*.5 $global-padding*.75;
    border-left: 6px solid $secondary-color;
    font-weight: bold;
    color: scale-color($secondary-color, $lightness: -30%);
    margin-bottom: $global-margin;

    &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        width: 100%;
        height: 0;
        border-bottom: 1px solid $secondary-color;
    }
}

form.append,
.email,
.gis .info {
    .grid-x {
        border-style: solid;
        border-width: 0 1px 1px 1px;
        border-color: $medium-gray;

        &:first-child {
            border-top-width: 1px;
        }


        .cell {
            padding-top: $global-padding*0.5;
            padding-bottom: $global-padding*0.5;

            &.title {
                border-right: 1px solid $medium-gray;
                background-color: scale-color($secondary-color, $lightness: 85%);

                .required {
                    color: $alert-color;
                }
            }

            label.middle,
            .switch {
                margin-bottom: 0;
            }

            [type='text'],
            [type='password'],
            [type='date'],
            [type='datetime'],
            [type='datetime-local'],
            [type='month'],
            [type='week'],
            [type='email'],
            [type='number'],
            [type='search'],
            [type='tel'],
            [type='time'],
            [type='url'],
            [type='color'],
            textarea,
            select,
            .input-group,
            .button {
                margin-bottom: 0;
            }
        }
    }

    .star {
        border-top-width: 1px;
    }

    .end {
        margin-bottom: $global-margin*2;
    }

}

.filter {
    label {
        margin-right: $global-margin*0.5;
    }
}

table {
    white-space: nowrap;

    a {
        color: $primary-color;
    }

    .min {
        width: 1%;
    }

    &.segmentation {

        tbody th,
        tbody td {
            border-right: 1px solid scale-color($secondary-color, $lightness: -25%);
        }

        tbody th {
            text-align: left;
        }

        tbody td {
            text-align: center;
        }

        thead th {
            text-align: center;
        }

        input {
            margin: 0;
        }

        .button-group {
            margin: 0;
            flex-wrap: nowrap;
        }
    }

    .button {
        margin: 0;
    }

    .deactivate {
        color: $alert-color;
    }

    .success {
        color: $success-color;
    }
}

.gis {
    position: relative;
    background-color: $light-gray;

    .control {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 1001;
    }

    .info {
        position: absolute;
        left: 16px;
        top: 16px;
        z-index: 1004;

        .content {
            width: 35vw;
            background-color: $white;
            margin-bottom: $global-margin;
            position: relative;

            ul {
                margin-bottom: 0;
            }

            .cell {
                align-items: center;
                display: flex;
                padding-top: $global-padding*0.5;
                padding-bottom: $global-padding*0.5;

                &.title {
                    justify-content: center;
                }
            }

            .content-title {
                font-size: $global-font-size*1.2;
                margin: 0;
                text-align: center;
                padding: $global-padding*0.5 0;
                background-color: $light-gray;
                border-style: solid;
                border-width: 1px 1px 0 1px;
                border-color: $medium-gray;
            }

            .close-button {
                top: 4px;
            }
        }
    }

    .zoom {
        position: absolute;
        right: 16px;
        bottom: 16px;
        z-index: 1003;
        width: 56px;
        font-weight: bold;
    }

    .basemap {
        position: absolute;
        left: 16px;
        bottom: 16px;
        z-index: 1002;
        transition: 0.25s ease-in-out;

        button {
            position: relative;
            transition: 0.25s ease-in-out;

            img {
                border-radius: 4px;
            }
        }

        &:hover {
            margin-bottom: 2px;

            button {
                cursor: pointer;
                box-shadow: 0 0 8px rgba($black, 0.25);
            }
        }

    }
}

.forget {
    .prompt {
        color: $alert-color;
    }
}

#warning {
    p {
        color: $alert-color;
    }
}

.tabs {
    &.append {
        display: inline-flex;
        border: none;
    }
}

.tabs-content {
    &.append {
        border-top: 1px solid #487868;

        table {
            margin: 0;

            .sort {
                input {
                    width: 100px;
                    text-align: center;
                }
            }
        }
    }
}

.switch {
    &.destroy {
        .switch-paddle {
            width: 5rem;
        }

        input:checked~.switch-paddle::after {
            left: 3.2rem;
        }

        .switch-inactive {
            right: 7%;
        }
    }
}