/* Customize the label (the checkbox) */
.checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkbox .checkmark {
    position: inherit;
    display: inline-block;
    height: 24px;
    width: 24px;
    background-color: $light-gray;
    border-radius: 2px;
    border: 1px solid rgba($black, 0.25);
    box-shadow: inset 0 0 8px rgba($black, 0.15);
    vertical-align: -7px;
    margin-right: 4px;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input~.checkmark {
    background-color: $medium-gray;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked~.checkmark {
    background-color: $success-color;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid $white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/* Customize the label (the radio) */
.radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: $black;
}

/* Hide the browser's default radio button */
.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio button */
.radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $medium-gray;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio input:checked~.checkmark {
    background-color: $success-color;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}